// Map JS
export default class Map {
	constructor(map) {
		this.html = document.querySelector('html');
		this.body = document.querySelector('body');
		this.maps = map;
		this.init();
		// this.initMap();
	}

	init() {
		if (this.maps) {
			this.maps.forEach(item => {
				this.initMap(item);
			});
		}
	}

	initMap(mapElement) {
		let latData = Number(mapElement.dataset.lat);
		let lngData = Number(mapElement.dataset.lng);

		var myLatLng = { lat: latData, lng: lngData };
		// Styles a map in night mode.
		var map = new google.maps.Map(mapElement, {
			center: myLatLng,
			zoom: 15,
			styles: [
				{
					"stylers": [
						{
							"saturation": -100
						},
						{
							"gamma": 1
						}
					]
				},
				{
					"elementType": "labels.text.stroke",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi.business",
					"elementType": "labels.text",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi.business",
					"elementType": "labels.icon",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi.place_of_worship",
					"elementType": "labels.text",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi.place_of_worship",
					"elementType": "labels.icon",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "geometry",
					"stylers": [
						{
							"visibility": "simplified"
						}
					]
				},
				{
					"featureType": "water",
					"stylers": [
						{
							"visibility": "on"
						},
						{
							"saturation": 50
						},
						{
							"gamma": 0
						},
						{
							"hue": "#50a5d1"
						}
					]
				},
				{
					"featureType": "administrative.neighborhood",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#333333"
						}
					]
				},
				{
					"featureType": "road.local",
					"elementType": "labels.text",
					"stylers": [
						{
							"weight": 0.5
						},
						{
							"color": "#333333"
						}
					]
				},
				{
					"featureType": "transit.station",
					"elementType": "labels.icon",
					"stylers": [
						{
							"gamma": 1
						},
						{
							"saturation": 50
						}
					]
				}
			]
		});
		var marker = new google.maps.Marker({
			position: myLatLng,
      map: map
		});

	}


}