'use strict'

import Masonry from 'masonry-layout'
import imagesLoaded from 'imagesloaded'

let self

export default self = {
    container: document.querySelector('.grid-layout'),
    msnry: null,

    init : () => {
        self.container = document.querySelector('.grid-layout');
        if(self.container) {
          self.msnry = new Masonry( self.container, {
            columnWidth: '.grid-layout-box',
            itemSelector: '.grid-layout-box',
            percentPosition: true,
            gutter: '.gutter-sizer'
          });
          self.gridLayout();
        }
    },

    gridLayout : () => {
      const gallery = document.querySelector('.home__gallery');
      imagesLoaded( gallery , () => {
        self.msnry.layout();
        gallery.classList.add('is-loaded');
      }); 
    }
}