'use strict';

// Dependancies
import "babel-polyfill"

import PageTransition from './modules/pageTransition'
import pageScrollReveal from './modules/pageScrollReveal'
import GridLayout from './modules/gridLayout'
import Map from './modules/map'
import ScrollToTop from './modules/scrollToTop'


PageTransition.init();
pageScrollReveal.init();
GridLayout.init();

const maps = [...document.querySelectorAll('.map')];
new Map(maps);

ScrollToTop.init();

