'use strict'

import Barba from 'barba.js';
import {TweenLite, TimelineLite} from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import GridLayout from './gridLayout'
import PageScrollReveal from './pageScrollReveal'
import Map from './map'
import scrollToTop from './scrollToTop'

let self

export default self = {

  init : () => {
    Barba.Pjax.init();
    Barba.Prefetch.init();

    // Google Analytics
    Barba.Dispatcher.on('initStateChange', function() {
      if (typeof ga === 'function') {
        ga('send', 'pageview', location.pathname);
      }
    });

    //---------------------------------------//
    //       When Transition Completed       //
    //---------------------------------------//
    Barba.Dispatcher.on('transitionCompleted', function(currentStatus) {

        // Prevent Reloading When User click a link that is a same page as a current page
        const links = document.querySelectorAll('a[href]');
        const cbk = function(e) {
        if(e.currentTarget.href === window.location.href) {
        e.preventDefault();
        e.stopPropagation();
        }
        };
        for(let i = 0; i < links.length; i++) {
        links[i].addEventListener('click', cbk);
        }

        if (currentStatus.url.search('artists') !== -1) FilterArtists.init();
    });

    let lastElementClicked;

    Barba.Dispatcher.on('linkClicked', function(el) {
        lastElementClicked = el;
    });

    //---------------------------------------//
    //               Basic                   //
    //---------------------------------------//
    const basic = Barba.BaseView.extend({
      namespace: "basic",
      scrollToTop: null,
      onEnter: function() {
          this._construct();
      },
      onEnterCompleted: function() {
          this.start();
      },
      _construct: function() {
          this.destroy();
      },
      destroy: function() {
          this.scrollToTop = null;
      },
      start: function() {
          this.scrollToTop = scrollToTop;
          this.scrollToTop.init();
      }
    });
    basic.init();

    //---------------------------------------//
    //               Home                    //
    //---------------------------------------//
    const Home = Barba.BaseView.extend({
        namespace: "home",
        GridLayout: null,
        scrollToTop: null,
        onEnter: function() {
            this._construct();
            document.querySelector('.home__gallery').classList.remove('is-loaded');
        },
        onEnterCompleted: function() {
            this.start();
        },
        _construct: function() {
            this.destroy();
        },
        destroy: function() {
            this.GridLayout = null;
            this.scrollToTop = null;
        },
        start: function() {
            this.GridLayout = GridLayout;
            this.GridLayout.init();
            this.scrollToTop = scrollToTop;
            this.scrollToTop.init();
        }
    });
    Home.init();

    //---------------------------------------//
    //              Project                  //
    //---------------------------------------//
    const Project = Barba.BaseView.extend({
        namespace: "project",
        PageScrollReveal: null,
        scrollToTop: null,
        onEnter: function() {
            this._construct();
        },
        onEnterCompleted: function() {
            this.start();
        },
        _construct: function() {
            this.destroy();
        },
        destroy: function() {
            this.PageScrollReveal = null;
            this.scrollToTop = null;
        },
        start: function() {
            this.PageScrollReveal = PageScrollReveal;
            this.PageScrollReveal.init();
            this.scrollToTop = scrollToTop;
            this.scrollToTop.init();
        }
    });
    Project.init();

    //---------------------------------------//
    //              Contact                  //
    //---------------------------------------//
    const contact = Barba.BaseView.extend({
      namespace: "contact",
      Map: null,
      scrollToTop: null,
      onEnter: function() {
          this._construct();
      },
      onEnterCompleted: function() {
          this.start();
      },
      _construct: function() {
          this.destroy();
      },
      destroy: function() {
          this.Map = null;
          this.scrollToTop = null;
      },
      start: function() {
        const maps = [...document.querySelectorAll('.map')];
          this.Map = Map;
          new this.Map(maps);
          this.scrollToTop = scrollToTop;
          this.scrollToTop.init();
      }
  });
  contact.init();


    //---------------------------------------//
    //            Page Transition            //
    //---------------------------------------//

    const PageAnimation = Barba.BaseTransition.extend({

        start: function() {
            Promise
                .all([this.newContainerLoading])
                .then(this.pageCheck.bind(this))
                .then(this.fadeInOut.bind(this));
        },

        fadeInOut: function() {
            const _this = this;
            const tl = new TimelineLite();

            return tl
            .set([this.newContainer], {y: '30px', autoAlpha: 0})
            .to(this.oldContainer, 0.6, {autoAlpha: 0})
            .set(this.oldContainer, {display: 'none'})
            .to(window, 0.001, {scrollTo:'body'}, '-=0.2')
            .to(this.newContainer, 0.6, {y: '0px', autoAlpha: 1})
            .fromTo('.header__close p', 0.6, {y: '20px', autoAlpha: 0} ,{y: '0px', autoAlpha: 1})
            .fromTo('.cross-icon', 0.6, {y: '20px', autoAlpha: 0}, {y: '0px', autoAlpha: 1, onComplete: function() {
                TweenLite.set([_this.newContainer], { clearProps: 'all' });
                _this.done();
            }}, '-=0.3');
        },

        pageCheck: function () {
            const page = this.newContainer.dataset.namespace;
            if(page !== 'home'){
                this.newContainer.ownerDocument.body.classList.remove('front');
            } else {
                this.newContainer.ownerDocument.body.classList.add('front');
            }
        }


    });

    Barba.Pjax.getTransition = function() {
      return PageAnimation;
    };

  }
}